/* eslint-disable no-plusplus */
import { Suspense, lazy } from 'react';
import { Box, CircularProgress } from '@mui/material';

const Map = lazy(() => import('@components/map/InteractiveMap'));

export default function OrganizationMap(props) {
  const {
    cache,
    organizations,
    defaultZoom,
    onItemClick,
    onStateChange,
    loading,
  } = props;

  return typeof window !== 'undefined' && (
    <Suspense fallback={<Loading visible />}>
      <Map
        cache={cache}
        config={MAP_CONFIG}
        defaultZoom={defaultZoom}
        loading={loading}
        onItemClick={onItemClick}
        onStateChange={onStateChange}
        projects={organizations}
      />
      <Loading visible={loading} />
    </Suspense>
  );
}

function Loading({ visible }) {
  return (
    <Box
      sx={{
        pointerEvents: 'none',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        opacity: visible ? 1 : 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        transition: 'opacity 0.25s ease-in-out',
      }}
    >
      <CircularProgress variant={visible ? 'indeterminate' : 'determinate'} />
    </Box>
  );
}

const MAP_CONFIG = {
  clusterMarkerRadius: 44,
  markerRadius: 28,
};
